import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const RedText = styled.span`
  color: ${({ theme }) => theme.logoRed};
`;

const MainLogoText = styled.p`
  font-size: ${({ theme }) => theme.font.size.xl};
  font-weight: 600;
  color: ${({ theme }) => theme.logoGreen};
  margin: 0;
  text-align: center;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xxxl};
  }
`;

const SubLogoText = styled.p`
  font-size: ${({ theme }) => theme.font.size.xs};
  text-transform: uppercase;
  margin: 0;
  text-align: center;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  :visited {
    color: ${({ theme }) => theme.black};
  }
`;

const Logo = () => (
  <StyledLink to="/">
    <MainLogoText>
      Green<RedText>er</RedText>
    </MainLogoText>
    <SubLogoText>ARCHITEKTURA ZIELENI</SubLogoText>
  </StyledLink>
);

export default Logo;
