import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

const StyledIcon = styled.div`
  height: 25px;
  width: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;

  &:first-child {
    margin-top: auto;
  }

  svg {
    height: 100%;
    width: auto;
  }

  path {
    fill: black;
  }
`;

const Icon = ({ src }) => (
  <StyledIcon>
    <SVG src={src} />
  </StyledIcon>
);

export default Icon;
