import React from 'react';
import styled from 'styled-components';
import facebookIcon from '../../static/facebook-icon.svg';
import messengerIcon from '../../static/messenger-icon.svg';
import Icon from './Icon';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  width: unset;
  padding: 1rem;
`;

const SocialMenu = () => (
  <StyledWrapper>
    <a
      href="https://pl-pl.facebook.com/Greener-1137715922977846/"
      target="_blank"
      rel="noopener norefferer"
    >
      <Icon src={facebookIcon} />
    </a>
    <a href="http://m.me/1137715922977846" rel="noopener norefferer">
      <Icon src={messengerIcon} />
    </a>
  </StyledWrapper>
);

export default SocialMenu;
