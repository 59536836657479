import { createGlobalStyle } from 'styled-components';
import fontBonveno from '../assets/fonts/BonvenoCF.otf';
import fontRaleway from '../assets/fonts/Raleway.ttf';

const Typography = createGlobalStyle`

    @font-face {
        font-family: BonvenoCF;
        src: url(${fontBonveno});
    }
    
    @font-face {
        font-family: Raleway;
        src: url(${fontRaleway});
    }

  html {
    font-family: BonvenoCF, Raleway, Montserrat, 'Open Sans', sans-serif;
    color: var(--black);
  }
  
   a {
    color: var(--black);
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }

  .center {
    text-align: center;
  }
  
`;

export default Typography;
