import React from 'react';
import styled from 'styled-components';

const StyledHamburger = styled.button`
  padding: 15px;
  border: none;
  background: none;
  z-index: 9999;

  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;
const InnerHamburger = styled.div`
  position: relative;
  width: 20px;
  height: 2px;
  background-color: ${({ theme, isOpen }) =>
    isOpen === 'true' ? 'transparent' : theme.black};
  transition: background-color 0.25s ease-in;
  cursor: pointer;

  ::after,
  ::before {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: ${({ theme }) => theme.black};
    transition: transform 0.25s ease-in-out;
  }

  ::before {
    top: -6px;
    transform: translateY(${({ isOpen }) => (isOpen === 'true' ? '6px' : '0')})
      rotate(${({ isOpen }) => (isOpen === 'true' ? '45deg' : '0')});
  }

  ::after {
    top: 6px;
    transform: translateY(${({ isOpen }) => (isOpen === 'true' ? '-6px' : '0')})
      rotate(${({ isOpen }) => (isOpen === 'true' ? '-45deg' : '0')});
  }
`;

const Hamburger = ({ isOpen, ...props }) => (
  <StyledHamburger {...props}>
    <InnerHamburger isOpen={isOpen} />
  </StyledHamburger>
);

export default Hamburger;
