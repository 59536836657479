import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import SocialMenu from './SocialMenu';
import Hamburger from './Hamburger';
import Logo from './Logo';

const StyledWrapper = styled.nav`
  padding: 40px;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 9999;
  background-color: ${({ theme }) => theme.white};
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 8px ${({ theme }) => theme.primaryGreener};

  ${({ theme }) => theme.mq.desktop} {
    align-items: center;
  }
`;

const StyledLogo = styled(Logo)`
  position: relative;
  z-index: 9999;

  ${({ theme }) => theme.mq.desktop} {
    width: 150px; /*TODO*/
  }
`;

const MenuLinksWrapper = styled.ul`
  margin: 0;
  padding: 0 20px 0 0;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  display: none;

  ${({ theme }) => theme.mq.desktop} {
    display: flex;
  }
`;

const MenuLink = styled(Link)`
  list-style: none;
  margin: 20px 10px;
  color: ${({ theme }) => theme.black};
  display: none;
  text-decoration: none;
  position: relative;

  ${({ theme }) => theme.mq.desktop} {
    display: block;
  }

  :hover:before {
    visibility: visible;
    width: 100%;
  }

  ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.black};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    ::before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.black};
      visibility: visible;
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }
`;

const menuItems = [
  {
    name: 'strona główna',
    link: '/',
    activeClassName: 'active',
  },
  {
    name: 'o nas',
    link: '/onas',
    activeClassName: 'active',
  },
  {
    name: 'oferta',
    link: '/oferta',
    activeClassName: 'active',
  },
  {
    name: 'galeria',
    link: '/galeria',
    activeClassName: 'active',
  },
  {
    name: 'referencje',
    link: '/referencje',
    activeClassName: 'active',
  },
  {
    name: 'kontakt',
    link: '/kontakt',
    activeClassName: 'active',
  },
];

const MobileNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.white};
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
  flex-direction: column;
  transform: translateX(${({ isOpen }) => (isOpen === 'true' ? '0' : '-100%')});
  transition: transform 0.25s ease-in-out;

  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;

const MenuLinksWrapperMobile = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MenuLinkMobile = styled.li`
  text-decoration: none;
  list-style: none;
  margin: 20px 0;
  font-family: ${({ theme }) => theme.font.family.bonveno};
  opacity: 1;
  transition: opacity 0.25s 0.2s ease-in-out;
  cursor: pointer;
  position: relative;

  :hover:before {
    visibility: visible;
    width: 100%;
  }

  ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: black;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  :visited {
    color: ${({ theme }) => theme.black};
  }

  &.active {
    ::before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.black};
      visibility: visible;
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }
`;

const Nav = () => {
  const [isMenuOpen, setMenuState] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMobileNav = () => {
    setMenuState(!isMenuOpen);
  };

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <StyledWrapper id="navbar" data-active={scrolled ? 'true' : 'false'}>
      <StyledLogo />
      <MenuLinksWrapper>
        {menuItems.map((item) => (
          <MenuLink
            activeClassName={item.activeClassName}
            key={item.name}
            to={item.link}
          >
            {item.name}
          </MenuLink>
        ))}
        <SocialMenu />
      </MenuLinksWrapper>
      <Hamburger
        onClick={toggleMobileNav}
        isOpen={isMenuOpen ? 'true' : 'false'}
      />
      <MobileNav isOpen={isMenuOpen ? 'true' : 'false'}>
        <MenuLinksWrapperMobile>
          {menuItems.map((item) => (
            <MenuLinkMobile
              as={Link}
              activeClassName={item.activeClassName}
              key={item.name}
              to={item.link}
              onClick={toggleMobileNav}
            >
              {item.name}
            </MenuLinkMobile>
          ))}
        </MenuLinksWrapperMobile>
        <SocialMenu />
      </MobileNav>
    </StyledWrapper>
  );
};

export default Nav;
